import { INTERNAL_URLS } from 'config/urls'

const NAV_SERVICES_SUBMENU = [
  {
    to: INTERNAL_URLS.SERVICES_GYNECOLOGY,
    label: 'Gynecology',
  },
  {
    to: INTERNAL_URLS.SERVICES_PRENATAL,
    label: 'Prenatal Care & Obstetrics',
  },
  {
    to: INTERNAL_URLS.SERVICES_POSTNATAL,
    label: 'Postnatal Care',
  },
  {
    to: INTERNAL_URLS.SERVICES_MENOPAUSE,
    label: 'Menopause & Beyond',
  },
  {
    to: INTERNAL_URLS.SERVICES,
    label: 'View All Services',
  },
]

export const NAV_MENU = [
  { to: INTERNAL_URLS.PROVIDERS, label: 'Who We Are' },
  {
    to: INTERNAL_URLS.SERVICES,
    label: 'Services We Provide',
    style: 'dropdown',
    dropdown: NAV_SERVICES_SUBMENU,
  },
  { to: '/first-visit', label: 'Your First Visit' },
  // { to: '/patient-resources', label: 'Patient Resources' },
  { to: INTERNAL_URLS.CONTACT, style: 'button', label: 'Make an Appointment' },
]
