import React from 'react'
import cx from 'classnames'

import { SEO } from './SEO'
import { Navigation } from '../common/Navigation'
import { Footer } from '../common/Footer'

import styles from './Layout.module.scss'

const Layout = ({ children, className, seo = {} }) => (
  <div className={styles.wrapper}>
    <SEO {...seo} />
    <Navigation />
    <main className={cx(styles.content, className)}>{children}</main>
    <Footer />
  </div>
)

export default Layout
