import React from 'react'
import { Link } from 'gatsby'

import { EXTERNAL_URLS } from '../../../config/urls'
import { PHONE } from '../../../config/business-info'
import { PhoneLink } from '../Link/PhoneLink'

import Logo from '../../svg/Logo'
import styles from './Footer.module.scss'

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <nav className={styles.footerContent}>
        <div className={styles.footerColumnLogo}>
          <Logo
            className={styles.footerLogo}
            alt="Valencia Gynecology Associates Icon"
          />
        </div>

        <div className={styles.footerColumnInfo}>
          <div className={styles.footerSection}>
            <p className={styles.footerSectionHeader}>Our Location</p>
            <p className={styles.footerSectionEntry}>
              <span className={styles.entryLine}>Smyth Medical Plaza</span>
              <span className={styles.entryLine}>27871 Smyth Drive #102</span>
              <span className={styles.entryLine}>Valencia, CA 91355</span>
            </p>
            <div className={styles.footerSectionEntryLink}>
              <a
                href={EXTERNAL_URLS.GMAPS_DIRECTIONS}
                target="_blank"
                rel="noreferrer"
              >
                Get Directions
              </a>
            </div>
          </div>

          <div className={styles.footerSection}>
            <p className={styles.footerSectionHeader}>Our Hours</p>
            <p className={styles.footerSectionEntry}>
              <span className={styles.entryLine}>Monday through Friday</span>
              <span className={styles.entryLine}>9AM - 5PM</span>
            </p>
            <div className={styles.footerSectionEntryLink}>Closed Holidays</div>
          </div>

          <div className={styles.footerSection}>
            <div className={styles.footerSectionRow}>
              <p className={styles.footerSectionHeader}>Phone</p>
              <p className={styles.footerSectionEntry}>
                <span className={styles.entryLine}>
                  <PhoneLink phone={PHONE.OFFICE} className={styles.telLink}>
                    {PHONE.OFFICE}
                  </PhoneLink>
                </span>
              </p>
            </div>
            <div className={styles.footerSectionRow}>
              <p className={styles.footerSectionHeader}>Fax</p>
              <span className={styles.entryLine}>
                <PhoneLink phone={PHONE.FAX} className={styles.telLink}>
                  {PHONE.FAX}
                </PhoneLink>
              </span>
            </div>
          </div>
        </div>
      </nav>
    </footer>
  )
}
