import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

const GET_SEO_ATTRIBUTES = graphql`
  query getSEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`

export const SEO = ({ title, description }) => {
  const {
    site: {
      siteMetadata: {
        defaultTitle,
        defaultDescription,
        titleTemplate,
        siteUrl,
      },
    },
  } = useStaticQuery(GET_SEO_ATTRIBUTES)

  return (
    <Helmet
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={titleTemplate}
    >
      {/* General tags */}
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      {/* SEO */}
      <title>{title}</title>
      <meta name="description" content={description || defaultDescription} />

      {/* TODO: Add social image */}
      {/* <meta name="image" content={image} /> */}

      {/* TODO: Review this canonical url. This should change according to page url */}
      <link rel="canonical" href={siteUrl} />

      {/* Add more below! */}
    </Helmet>
  )
}
