const SERVICES_URL_BASE = '/services'

export const INTERNAL_URLS = {
  HOME: '/',
  CONTACT: '/contact-us',
  FIRST_VISIT: '/first-visit',
  OPERATING_HOURS: '/hours',
  PROVIDERS: '/providers',
  RESOURCES: '/patient-resources',
  SERVICES: `${SERVICES_URL_BASE}`,
  SERVICES_PRENATAL: `${SERVICES_URL_BASE}/prenatal`,
  SERVICES_POSTNATAL: `${SERVICES_URL_BASE}/postnatal`,
  SERVICES_GYNECOLOGY: `${SERVICES_URL_BASE}/gynecology`,
  SERVICES_MENOPAUSE: `${SERVICES_URL_BASE}/menopause`,
}

export const EXTERNAL_URLS = {
  GMAPS_DIRECTIONS: 'https://goo.gl/maps/9xr8rQRbFJE6cjhD7',
}
