import React from 'react'

const Logo = ({ className = '', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 104 120"
      className={`fill-current ${className}`}
      {...props}
    >
      <path
        fillOpacity="0.219"
        fillRule="evenodd"
        d="M74.771 23c2.858 2.157 2.426 10.966-.69 15.545-1.372 2.014-3.155 3.037-3.943 5.602-.754 2.454-.568 6.835-.29 9.03.153 1.208.508 2.053-.651 2.193-1.166.14-2.81-.112-2.962-.066-1.589.478-1.756 3.306-1.63 3.933.041.204-.403.453-.652.546-.422.158-1.118.024-1.266.242-.496.728-.191 1.646-.719 1.857-1.27.511-2.336.126-3.267 1.753-.833 1.596-.894 7.685-10.231.643-1.479-1.116-2.59-2.137-4.184-3.04-8.904-5.046-12.217 26.648 10.218 25.91 4.5-.148 7.735-2.335 3.877-4.868-1.474-.968-1.647-2.913-.39-4.044 1.17-1.051-.071-2.278.167-2.512.051-.047.535-.414.563-.495.023-.062-.044-.142-.067-.205-.105-.3-.377-1.132-.185-1.295.244-.205 1.848-1.342 1.87-1.466.065-.387-.302-.873-.335-1.37-.06-.924.28-1.647 1.1-1.457 1.362.312 2.659.495 3.882-.882 1.843-2.073 1.419-2.78 3.506-4.616 2.439-2.144 6.138-2.457 9.098-1.695 7.231 1.438 14.75 7.938 14.398 14.596-.295 6.688-3.394 12.369-10.24 13.407-2.559.388-12.029.349-12.504 3.496C62.622 137.685-23.54 85.282 74.77 23zm25.243 58.807c10.758-16.51-1.788-37.853-19.43-35.806 1.483-5.587 4.29-18.3-.96-27.863C71.861 3.998 57.256-.827 42.927.113c-11.682.766-21.131 5.532-28.16 12.644-21.17 21.42-17.3 49.643-2.702 74.035 10.933 18.267 27.685 33.084 39.457 33.207 14.48.15 40.925-24.886 48.492-38.192z"
      ></path>
    </svg>
  )
}

export default Logo
