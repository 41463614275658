/**
 * Inspired by
 * @see https://dev.to/fayaz/making-a-navigation-drawer-sliding-sidebar-with-tailwindcss-blueprint-581l
 */
import React, { useState, useCallback, useRef } from 'react'
import cx from 'classnames'
import { IoMenuOutline as MenuIcon } from 'react-icons/io5'
import { IoCloseCircleOutline as CloseIcon } from 'react-icons/io5'

import { useOnRouteChange } from 'utils/hooks/useOnRouteChange'
import { useClickAway } from 'utils/hooks/useClickAway'
import { useLockBodyScroll } from 'utils/hooks/useLockBodyScroll'

import { NavLogo } from '../NavLogo'
import { NavMenu } from '../NavMenu'

import styles from '../Navigation.module.scss'

export const Sidebar = () => {
  const sidebarRef = useRef(null)
  const [open, setOpen] = useState(false)

  // Ensures the open menu handler and onClickAway won't clash
  const openMenu = () => setOpen(_open => (_open ? _open : true))

  // `React.useEffect` will re-run when an item defined in the dependency array
  // is changed. Because `closeMenu` is recreated on every re-execution of this
  // component, we use useCallback to not update the identity unless `setOpen`'s
  // identity changes.
  const closeMenu = useCallback(() => setOpen(false), [setOpen])

  // Close menu on route change
  useOnRouteChange(() => closeMenu())

  // Closes menu when clicked off
  useClickAway(sidebarRef, () => closeMenu())

  // Disables scroll on the body when the sidebar is open
  useLockBodyScroll(open)

  return (
    <>
      <div className={styles.mobileNavMenuBtns}>
        <button
          onClick={openMenu}
          className={cx(styles.mobileNavMenuOpenBtn, {
            [styles.mobileNavMenuBtnShown]: open,
            [styles.mobileNavMenuBtnHidden]: !open,
          })}
        >
          {/* sr-only only displays this content for screenreaders */}
          <span className="sr-only">Open Menu</span>
          <MenuIcon className={styles.mobileNavMenuBtnIcon} />
        </button>

        <button
          className={cx(styles.mobileNavMenuCloseBtn, {
            [styles.mobileNavMenuBtnShown]: !open,
            [styles.mobileNavMenuBtnHidden]: open,
          })}
          onClick={closeMenu}
        >
          {/* sr-only only displays this content for screenreaders */}
          <span className="sr-only">Close Menu</span>
          <CloseIcon className={styles.mobileNavMenuBtnIcon} />
        </button>
      </div>

      <aside
        ref={sidebarRef}
        className={cx(styles.mobileNavSidebar, {
          [styles.mobileNavSidebarOpened]: open,
          [styles.mobileNavSidebarClosed]: !open,
        })}
        tabIndex={open ? '0' : ''}
      >
        <nav className="p-8">
          <NavLogo className="inline-block mb-8" />

          <div className="flex flex-col space-y-6">
            <NavMenu />
          </div>
        </nav>
      </aside>
    </>
  )
}
