import React, { useState } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { IoChevronDownOutline as ChevronDownIcon } from 'react-icons/io5'
import { useIsCurrentPath } from '../../../../../utils/hooks/useIsCurrentPath'

import ArrowRight from 'components/svg/ArrowRight'

import styles from '../../Navigation.module.scss'

export const NavLinkDropdown = ({ to, label, dropdown = [] }) => {
  const [open, setOpen] = useState()
  const isActive = useIsCurrentPath(to)
  const toggleDropdown = () => setOpen(open => !open)

  return (
    <div className={styles.navLinkWithDropdown} role="dropdown">
      <button
        className={cx(styles.navLink, { [styles.navLinkActive]: isActive })}
        onClick={toggleDropdown}
      >
        {label} <ChevronDownIcon className="inline lg-hidden" />
      </button>

      <div
        className={cx(styles.navLink, styles.navDropdownLinkWrapper, {
          'hidden lg-block': !open,
        })}
      >
        <div
          className={cx(styles.navDropdownLinks, {
            'flex flex-col lg-block mt-4 lg-mt-0': open,
          })}
        >
          {dropdown.map(({ to: itemTo, label: itemLabel }, idx) => {
            const isLastItem = idx === dropdown.length - 1
            const isIndex = to === itemTo

            return (
              <>
                {isLastItem && <hr className={styles.navDropdownLinkBorder} />}
                <Link
                  to={itemTo}
                  className={styles.navDropdownLink}
                  activeClassName={styles.navLinkActive}
                >
                  {itemLabel}
                  {isIndex && <ArrowRight className={styles.rightArrowIcon} />}
                </Link>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}
