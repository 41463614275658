import { useEffect } from 'react'

/**
 * **useLockBodyScroll**
 *
 * React side-effect hook that locks document body by adding `overflow: hidden`
 * when passed `true`, or remove it when passed `false`.
 *
 * Useful for locking scroll when mobile nav is open.
 *
 * @param {boolean} locked
 * @example
 * ```
 * const [navOpen, setNavOpen] = useState(false)
 *
 * useLockBodyScroll(navOpen)
 * ```
 *
 * ---
 *
 * Simpler implementation of react-use's `useLockBodyScroll`
 * @see [react-use's `useLockBodyScroll`](https://github.com/streamich/react-use/blob/73a5954e811ff1525da74583835340650818ac5c/src/useLockBodyScroll.ts#L50)
 */
export const useLockBodyScroll = locked => {
  useEffect(() => {
    if (locked) lock()
    if (!locked) unlock()

    // cleanup, on unmount
    return () => unlock()
  }, [locked])
}

function lock() {
  document.body.style.overflow = 'hidden'
}

function unlock() {
  document.body.style.removeProperty('overflow')
}
