import React from 'react'

const ArrowRight = ({ className = '', ...props }) => {
  return (
    <svg className={className} width="13px" height="13px" viewBox="0 0 13 13" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-981.000000, -1665.000000)" fill="#DB79D4">
          <g transform="translate(120.000000, 1156.000000)">
            <polygon id="Shape" transform="translate(867.500000, 515.500000) scale(-1, -1) translate(-867.500000, -515.500000) " points="864.128211 514.6875 868.678287 510.137424 867.5 509 861 515.5 867.5 522 868.637386 520.862538 864.128211 516.3125 874 516.3125 874 514.6875"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ArrowRight
