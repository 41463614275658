import React from 'react'

import { NavLogo } from './NavLogo'
import { NavMenu } from './NavMenu'
import { Sidebar } from './Sidebar'

import styles from './Navigation.module.scss'

export const Navigation = () => {
  return (
    <header className={styles.nav}>
      <NavLogo />
      <Sidebar />
      <nav className="hidden lg-flex">
        <NavMenu />
      </nav>
    </header>
  )
}
