import { useEffect, useRef } from 'react'
import { useLocation } from '@reach/router'

/**
 * useOnRouteChange - React side-effect hook that runs a callback when the route changes
 *
 * @param {function} callback
 */
export const useOnRouteChange = cb => {
  const { pathname } = useLocation()
  const savedCallback = useRef(cb)

  // Update the callback if a new one is passed
  useEffect(() => {
    savedCallback.current = cb
  }, [cb])

  // Run callback on pathname change
  useEffect(() => {
    savedCallback.current({ pathname })
  }, [pathname])
}
