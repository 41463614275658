import React from 'react'
import { Link } from 'gatsby'

import styles from '../../Navigation.module.scss'

export const NavLinkBtn = ({ to, ...linkProps }) => {
  return (
    <div className="flex items-center">
      <Link className={styles.navLinkBtn} to={to} {...linkProps} />
    </div>
  )
}
