import React from 'react'
import { Link } from 'gatsby'

import styles from '../../Navigation.module.scss'

export const NavLink = ({ to, ...linkProps }) => {
  return (
    <div className="flex items-center">
      <Link
        className={styles.navLink}
        activeClassName={styles.navLinkActive}
        to={to}
        {...linkProps}
      />
    </div>
  )
}
