import React from 'react'

import { NAV_MENU } from '../constants'
import { NavLink } from './NavLink'
import { NavLinkDropdown } from './NavLinkDropdown'
import { NavLinkBtn } from './NavLinkBtn'

export const NavMenu = () => {
  return NAV_MENU.map(({ to, label, style = 'link', dropdown }, idx) => {
    switch (style) {
      case 'button':
        return (
          <NavLinkBtn to={to} key={idx}>
            {label}
          </NavLinkBtn>
        )

      case 'dropdown':
        return <NavLinkDropdown label={label} dropdown={dropdown} key={idx} />

      case 'link':
      default:
        return (
          <NavLink to={to} key={idx}>
            {label}
          </NavLink>
        )
    }
  })
}
