import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import { INTERNAL_URLS } from 'config/urls'

import NavLogoImg from 'components/images/nav-logo@2x.png'
import styles from '../Navigation.module.scss'

export const NavLogo = ({ className }) => {
  return (
    <Link className={cx(styles.navLink, className)} to={INTERNAL_URLS.HOME}>
      <img
        className={styles.navLogo}
        src={NavLogoImg}
        alt="Valencia Gynecology Associates Logo"
      />
    </Link>
  )
}
